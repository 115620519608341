@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Baloo 2", sans-serif;
  background-color: #f7f7fd;
}

html,
body {
  scroll-behavior: smooth;
}

.active {
  border-radius: 0 20px 20px 0px;
  width: 90% !important;
  background-color: #f7f7fd;
  color: #6666ea !important;
}

.active p {
  color: #6666ea;
}

.dashIconActive {
  color: #6666ea;
}

.ps-menu-button:hover {
  border-radius: 0 20px 20px 0px;
  background-color: #f7f7fd !important;
  color: #000 !important;
  width: 90%;
}

/* class page scroll custom */
#horizontalTab::-webkit-scrollbar {
  height: 4px;
  background-color: #f5f5f5;
}

#horizontalTab::-webkit-scrollbar-thumb {
  background-color: #65656547;
  border-radius: 10px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.fc-header-toolbar {
  display: inline-block;
  padding: 4px 4px;

  @apply space-x-8;
}

.fc-day {
  @apply m-8;
}

.fc-scrollgrid-sync-table {
  @apply w-full;
}

.fc-view {
  @apply w-full h-full  bg-white;
}

.fc-h-event {
  @apply bg-[#A3A3F2] border border-defaultBlue;
}

.fc .fc-daygrid-day.fc-day-today {
  @apply bg-violet-100;
}

.fc .fc-button {
  @apply bg-[#A3A3F2] hover:bg-defaultBlue_60;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  @apply bg-[#A3A3F2];
}

.fc .fc-button-primary:disabled {
  @apply bg-defaultBlue;
}
.fc-v-event {
  @apply bg-defaultBlue_60 border border-defaultBlue;
}

.futuristic-select {
  background: linear-gradient(
    135deg,
    rgba(102, 102, 234, 0.8),
    rgba(255, 255, 255, 0.1)
  );
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(102, 102, 234, 0.4);
  transition: all 0.3s ease-in-out;
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.futuristic-select:hover {
  background: linear-gradient(
    135deg,
    rgba(102, 102, 234, 0.9),
    rgba(255, 255, 255, 0.2)
  );
  transform: scale(1.05);
  box-shadow: 0 6px 16px rgba(102, 102, 234, 0.6);
}

/* .ant-select-dropdown {
  background-color: #0f0f24;
  color: white;
} */

/* .ant-modal-content {
  background: linear-gradient(
    135deg,
    rgba(20, 20, 50, 0.8),
    rgba(30, 30, 70, 0.9)
  );
  border-radius: 15px;
  color: white;
} */

/* .custom-placeholder .ant-select-selection-placeholder {
  color: #0f0f24 !important;
} */

.fc-timegrid-slot {
  height: 50px !important;
}
.custom-timepicker .ant-picker-ok .ant-btn {
  color: #0f0f24 !important;
}
.customText:hover {
  color: #6666ea !important;
  border: none;
}

.ant-picker-ok span {
  color: black !important;
}
